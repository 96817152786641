import {
  Col,
  Collapse,
  ConfigProvider,
  Empty,
  Row,
  Segmented,
  Select,
  Space,
  Tooltip,
} from "antd"
import Search from "antd/es/input/Search"
import React, { useEffect, useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import TableCustom, { SELECTION_COLUMN } from "src/components/Table/CustomTable"
import { MainTableData } from "src/components/Table/CustomTable/styled"
import { StyleSearch } from "src/pages/ADMIN/BookUse/styled"
import ModalReport from "./Modals/ListReport/ModalReport"
import FormService from "src/services/FormSevice"
import { useParams } from "react-router-dom"
import STORAGE, { getStorage } from "src/lib/storage"
import DetailsReport from "./Modals/ListReport/DetailsReport"
import ListModal from "./Modals/ListReport/ListModal"
import Notice from "src/components/Notice"
import PractiseService from "src/services/PractiseService"
import UploadFile from "./Modals/UploadFile"

const ListReport = ({ detail }) => {
  const userInfo = getStorage(STORAGE.USER_INFO)
  const { id } = useParams()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState()
  const [pagination, setPagination] = useState({
    page_size: 10,
    page_number: 1,
    status: undefined,
    team_type: 0,
    teamId: undefined,
  })
  const [modalAddFile, setModalAddFile] = useState(false)
  const [team, setTeam] = useState()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false)
  const [isModalOpenReport, setIsModalOpenReport] = useState(false)
  const [totalError, setTotalError] = useState()
  const [dataComment, setDataComment] = useState()
  const [dataDetail, setDataDetail] = useState()

  const [dataSelect, setDataSelect] = useState([])
  const [total, setTotal] = useState()
  const [tabStatus, setTabStatus] = useState(
    userInfo?.role?.practise?.find(user => user.practise_id === id)
      .role_group_id == 4
      ? 1
      : userInfo?.role?.practise?.find(user => user.practise_id === id)
          .role_group_id == 5
      ? 2
      : 1,
  )
  const [type, setType] = useState()
  const [button, setButtonData] = useState()
  const roleId = userInfo?.role?.practise?.find(
    user => user.practise_id === id,
  ).role_group_id

  const columns = [
    SELECTION_COLUMN,
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        // <div className="text-center">
        //     {idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        // </div>
        <div className="text-center">
          {(pagination.page_number - 1) * pagination.page_size + idx + 1}
        </div>
      ),
      align: "center",
    },
    {
      title: (
        <>
          <MainTableData>Tên báo cáo/Mô tả</MainTableData>
        </>
      ),
      key: "form_name",
      render: (val, record) => (
        <Tooltip
          title={
            <>
              <div>Tên báo cáo/Mô tả: {record?.form_name}</div>
            </>
          }
        >
          <MainTableData>{record?.form_name}</MainTableData>
        </Tooltip>
      ),
    },

    {
      title: "Mức độ",
      dataIndex: "value",
      key: "value",
      render: (val, record) => (
        <Tooltip title={<div>{record?.allcode?.value}</div>}>
          <MainTableData>{record?.allcode?.value}</MainTableData>
        </Tooltip>
      ),
      align: "center",
      width: 80,
    },
    {
      title: "Người gửi",
      dataIndex: "fullname",
      key: "fullname",
      width: 150,
      align: "center",
      render: (val, record) => (
        <Tooltip title={<div>{record?.User?.fullname}</div>}>
          <MainTableData>{record?.User?.fullname}</MainTableData>
        </Tooltip>
      ),
    },
    {
      title: "Khuyến nghị báo cáo",
      dataIndex: "feedback",
      key: "feedback",
      width: 200,
      render: (val, record) => (
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: val }} />}>
          <MainTableData
            dangerouslySetInnerHTML={{ __html: val }}
          ></MainTableData>
        </Tooltip>
      ),
    },
    {
      title: "Điểm",
      dataIndex: "point",
      key: "point",
      width: 50,
      align: "center",

      render: (val, record) => (
        <Tooltip title={<div>{val}</div>}>
          <MainTableData>{val}</MainTableData>
        </Tooltip>
      ),
    },
    {
      title: "Trạng thái",
      key: "status",
      align: "center",
      width: 150,

      render: (val, record) => (
        <div>
          <div>
            {record?.status === 0 ? (
              "Đang chờ"
            ) : record?.status === 1 ? (
              <div style={{ color: "blue", fontWeight: "600" }}>Tiếp nhận</div>
            ) : (
              <div style={{ color: "red", fontWeight: "600" }}>Từ chối</div>
            )}
          </div>
          <FloatActionWrapper size="small" className="float-action__wrapper">
            <Space>
              {button?.btn_Receive && (
                <ButtonCircle
                  title="Tiếp nhận"
                  iconName="check-blue-circle"
                  onClick={() =>
                    setIsModalOpen({
                      dataSelect: [record],
                      status: 1,
                      title: "Báo cáo tiếp nhận",
                    })
                  }
                />
              )}
              {button?.btn_Refuse && (
                <ButtonCircle
                  title="Từ chối"
                  iconName="cancel"
                  onClick={() =>
                    setIsModalOpen({
                      dataSelect: [record],
                      status: 2,
                      title: "Báo cáo từ chối",
                    })
                  }
                />
              )}
            </Space>
          </FloatActionWrapper>
        </div>
      ),
    },
  ]
  const ErrorColums = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        <div className="text-center">
          {(pagination.page_number - 1) * pagination.page_size + idx + 1}
        </div>
      ),
      align: "center",
    },
    // {
    //   title: (
    //     <>
    //       <MainTableData>Danh sách lỗi phát hiện</MainTableData>
    //     </>
    //   ),
    //   key: "name_error",
    //   width: 300,
    //   render: (val, record) => (
    //     <Tooltip
    //       title={
    //         <>
    //           <div>Tên báo cáo/Mô tả: {record?.name_error}</div>
    //         </>
    //       }
    //     >
    //       <MainTableData>{record?.name_error}</MainTableData>
    //     </Tooltip>
    //   ),
    // },

    {
      title: "Mức độ ảnh hưởng",
      dataIndex: "name",
      key: "name",
      render: (val, record) => (
        <Tooltip title={<div>{val}</div>}>
          <MainTableData>{val}</MainTableData>
        </Tooltip>
      ),
      align: "center",
    },
    {
      title: "Số lỗi",
      dataIndex: "total",
      key: "total",
      render: (val, record) => (
        <Tooltip title={<div>{val}</div>}>
          <MainTableData>{val}</MainTableData>
        </Tooltip>
      ),
      align: "center",
    },
  ]
  const columnsTab = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        <div className="text-center">
          {idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Tên đội",
      dataIndex: "teamName",
      key: "teamName",
      render: (val, record, idx) => (
        <div className="text-center">
          <h5>{record?.teamName}</h5>
        </div>
      ),
    },
  ]

  const getData = async event => {
    try {
      setLoading(true)
      const apiCall =
        tabStatus === 3
          ? FormService.getErrorForms
          : FormService.getFormsProcedure
      const resp = await apiCall({
        ...pagination,
        practise_id: id,
        team_type: tabStatus,
        teamId:
          roleId == 4
            ? undefined
            : roleId == 5
            ? undefined
            : !!pagination?.teamId
            ? pagination?.teamId
            : event,
      })
      setDataComment()

      if (resp.isOk) {
        setButtonData(resp?.button)
        setType(resp.type)
        if (tabStatus === 3) {
          setTotalError(resp)
          setDataComment(resp?.data?.listTotal?.arr)
        } else {
          setDataComment(resp?.data)
        }
        setTotal(resp?.total)
        if (!!resp?.data?.listDetail) {
          const items = resp?.data?.listDetail.map((item, index) => {
            return {
              key: index++,
              label: (
                <>
                  {" "}
                  {item?.name_error} | Số lượng: {item?.values}
                </>
              ),
              children: (
                <div className="list-detail ml-24">
                  {/* <span>Số lượng: </span>
                  {item?.values} */}
                  {/* {item?.values?.map(event => {
                    return (
                      <ul>
                        <li>
                          <span>Mô tả:</span>
                          {event?.description}
                        </li>
                        <li>
                          <span>Mức độ: </span>
                          {event?.level}
                        </li>
                        <li>
                          <span>Url xuất hiện lỗi: </span> {event?.url_error}
                        </li>
                        <li>
                          <span>Tác động: </span>
                          {event?.impact}
                        </li>
                        <li>
                          <span>PoC: </span>
                          {event?.PoC}
                        </li>
                        <hr />
                      </ul>
                    )
                  })} */}
                </div>
              ),
            }
          })
          setDataDetail(items)
        } else {
          setDataComment(resp?.data)
        }
      }
    } finally {
      setLoading(false)
    }
  }
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setDataSelect(selectedRows)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: record =>
    //     disabled: record?.contribute_status !== 0,
    //     key: record.id

    // }),
    getCheckboxProps: record => {
      return {
        // disabled: record.contribute_status !== 0,
        name: record.id,
      }
    },
  }

  const handleResult = async () => {
    try {
      setLoading(true)
      const res = await PractiseService.allowPublishResult({
        practise_id: id,
      })
      if (res.isOk) {
        Notice({ msg: res.message, isSuccess: true })
      }
    } finally {
      setLoading(false)
    }
  }
  const handleChangeSubmit = async event => {
    try {
      setLoading(true)
      const res = await FormService.receiveForm({
        status: event?.status,
        practise_id: id,
        form_id: event?.record?.form_id,
        point: event?.record?.points,
        level: !!event?.record?.level ? event?.record?.level : undefined,
        feedback: !!event?.record?.feedback
          ? event?.record?.feedback
          : undefined,
      })
      if (res.isError) return
      if (res.isOk) {
        Notice({
          isSuccess: true,
          msg: "Thêm tập tin thành công",
        })
        getData()
        setIsModalOpen(false)
      }
    } finally {
      setLoading(false)
    }
  }
  const expandedRowRender = record => {
    return (
      <TableCustom
        isPrimary
        columns={columns}
        rowSelection={rowSelection}
        rowKey={record => `${record?.id}`}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              setIsModalOpenDetail(record)
            }, // click row
          }
        }}
        dataSource={record.listForm.map(item => ({
          ...item,
          key: item.id,
        }))}
        pagination={false}
      />
    )
  }
  const getListplan = async () => {
    try {
      setLoading(true)
      const res = await PractiseService.getTeamInPractise({
        practise_id: id,
      })
      if (res.isOk) {
        const ls = res.data.map(item => ({
          value: item?.id,
          label: item?.team_name,
        }))
        getData(res.data[0].id)
        setTeam(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getListplan()
    // getData()
  }, [tabStatus, pagination])
  const userRoleGroupId =
    userInfo?.role?.practise?.find(user => user.practise_id === id)
      ?.role_group_id == 4
      ? ["Đội tấn công", "Báo cáo tổng hợp lỗi"]
      : userInfo?.role?.practise?.find(user => user.practise_id === id)
          ?.role_group_id == 5
      ? ["Đội ứng cứu sự cố", "Báo cáo tổng hợp lỗi"]
      : ["Đội tấn công", "Đội ứng cứu sự cố", "Báo cáo tổng hợp lỗi"]
  return (
    <div>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <Row gutter={[10]}>
            <Col span={18}>
              <StyleSearch>
                <Search
                  placeholder="Nhập tên cần tìm kiếm"
                  allowClear
                  onSearch={(value, _e, info) => {
                    setPagination({
                      ...pagination,
                      input: value,
                    })
                  }}
                  // onBlur={handleBlur}
                  // style={{
                  //     width: 200,
                  // }}
                />
              </StyleSearch>
            </Col>
            <Col span={6}>
              <Select
                style={{
                  width: "100%",
                }}
                allowClear
                showSearch
                placeholder="Trạng thái"
                optionFilterProp="children"
                onChange={item => {
                  setPagination({
                    ...pagination,
                    status: item,
                  })
                }}
                options={[
                  {
                    value: "0",
                    label: "Đã nhận",
                  },
                  {
                    value: "1",
                    label: "Chờ nhận",
                  },
                ]}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemSelectedBg: "#01638d",
                  itemSelectedColor: "#ffffff",
                },
              },
            }}
          >
            <Segmented
              itemSelectedBg="blue"
              defaultValue={
                userInfo?.role?.practise?.find(user => user.practise_id === id)
                  .role_group_id == 4
                  ? "Đội tấn công"
                  : userInfo?.role?.practise?.find(
                      user => user.practise_id === id,
                    ).role_group_id == 5
                  ? "Đội ứng cứu sự cố"
                  : "Đội tấn công"
              }
              options={userRoleGroupId}
              onChange={value => {
                switch (value) {
                  case "Đội tấn công":
                    setTabStatus(1)
                    break
                  case "Đội ứng cứu sự cố":
                    setTabStatus(2)
                    break
                  case "Báo cáo tổng hợp lỗi":
                    setTabStatus(3)
                    break
                  default:
                    break
                }
              }}
            />
          </ConfigProvider>
        </Col>
        <Col span={24}>
          <div className="title-type-1">
            <Row className="justify-content-space-between">
              <Col className="d-flex" style={{ alignItems: "center" }}>
                <div className="Text-title">
                  <h2>Danh sách báo cáo ({total})</h2>
                  <div>
                    Vui lòng gửi báo cáo kết quả theo mẫu của BTC về địa chỉ
                    email: dttc@vncert.vn
                  </div>
                </div>
              </Col>
              {!detail && (
                <Col>
                  {tabStatus !== 3 ? (
                    <Space>
                      {button?.btn_AddReport && (
                        <Space>
                          <Button
                            btnType="primary"
                            onClick={() =>
                              setIsModalOpenReport({
                                status: tabStatus,
                                practise_id: id,
                                user_id: userInfo?.id,
                              })
                            }
                          >
                            + Thêm báo cáo
                          </Button>
                          <Button
                            btnType="primary"
                            onClick={() =>
                              setModalAddFile({
                                status: tabStatus,
                                practise_id: id,
                                user_id: userInfo?.id,
                              })
                            }
                          >
                            Thêm file báo cáo
                          </Button>
                        </Space>
                      )}
                      {button?.btn_Receive && (
                        <Button
                          btnType="primary"
                          onClick={() =>
                            setIsModalOpen({
                              dataSelect,
                              status: 1,
                              title: "Xác nhận đã tiếp tiếp nhận",
                            })
                          }
                          disabled={selectedRowKeys?.length > 0 ? false : true}
                        >
                          nhận
                        </Button>
                      )}
                      {button?.btn_Refuse && (
                        <Button
                          btnType="third"
                          onClick={() =>
                            setIsModalOpen({
                              dataSelect,
                              status: 2,
                              title: "Từ chối",
                            })
                          }
                          disabled={selectedRowKeys?.length > 0 ? false : true}
                        >
                          Từ chối
                        </Button>
                      )}
                    </Space>
                  ) : (
                    <Space>
                      {button?.btn_PushAll && (
                        <Button
                          btnType="primary"
                          onClick={() => handleResult()}
                        >
                          Công bố tổng
                        </Button>
                      )}
                      {button?.btn_PushDetail && (
                        <Button
                          btnType="primary"
                          // onClick={() =>
                          //   setIsModalOpen({
                          //     dataSelect,
                          //     status: 1,
                          //     title: "Xác nhận đã tiếp tiếp nhận",
                          //   })
                          // }
                          // disabled={selectedRowKeys?.length > 0 ? false : true}
                        >
                          Công bố chi tiết
                        </Button>
                      )}

                      {button?.btn_Export && (
                        <Button
                          btnType="primary"
                          // onClick={() =>
                          //   setIsModalOpen({
                          //     dataSelect,
                          //     status: 2,
                          //     title: "Từ chối",
                          //   })
                          // }
                          // disabled={selectedRowKeys?.length > 0 ? false : true}
                        >
                          Xuất báo cáo
                        </Button>
                      )}
                      {roleId == 4 ||
                        (roleId == 5 ? (
                          ""
                        ) : (
                          <Select
                            className="mb-7"
                            style={{
                              width: "200px",
                            }}
                            allowClear
                            showSearch
                            placeholder="Chọn đội"
                            optionFilterProp="children"
                            onChange={item => {
                              setPagination({
                                ...pagination,
                                teamId: item,
                              })
                            }}
                            defaultValue={team[0]?.value}
                            options={team}
                          />
                        ))}
                    </Space>
                  )}
                </Col>
              )}
            </Row>
          </div>
          <Col span={24}>
            <div className="Text-title" style={{ fontWeight: "700" }}>
              <h2>
                {tabStatus == 3 &&
                  `Tổng số lỗi: ${totalError?.data?.listTotal?.total || 0}`}
              </h2>
              <div></div>
            </div>
          </Col>
          {dataComment?.length === 0 ? (
            <Empty />
          ) : (
            <div style={{ width: "100%" }}>
              <TableCustom
                loading={loading}
                // rowSelection={tabStatus !== 3 && !type ? rowSelection : undefined}
                isPrimary
                columns={
                  tabStatus === 3 ? ErrorColums : type ? columnsTab : columns
                }
                dataSource={dataComment?.map((item, index) => ({
                  ...item,
                  key: index + 1,
                }))}
                sticky={{ offsetHeader: -14 }}
                scroll={{ x: "800px" }}
                style={{ marginBottom: 4 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {
                      setIsModalOpenDetail(type ? undefined : record)
                    }, // click row
                  }
                }}
                pagination={{
                  hideOnSinglePage: total <= 10,
                  current: pagination.page_number,
                  PageSize: pagination.page_size,
                  responsive: true,
                  total,
                  locale: { items_per_page: "" },
                  showSizeChanger: total > 10,
                  onChange: (page, page_size) => {
                    setPagination({
                      ...pagination,
                      page_number: page,
                      page_size: page_size,
                    })
                  },
                }}
                showPagination
                // rowKey={record => `${record?.id}`}
                footerLeft={<div className="d-flex mt-20" />}
                widthScroll={1200}
                expandable={{
                  expandedRowRender,
                  rowExpandable: record =>
                    record.listForm && record.listForm.length > 0,
                  // expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                  // onExpand: handleExpand,
                }}
              />
            </div>
          )}
        </Col>
        {!!dataDetail && tabStatus == 3 && (
          <Col span={24}>
            <Collapse
              items={dataDetail}
              defaultActiveKey={["1"]}
              onChange={event => {
                console.log(event)
              }}
            />
          </Col>
        )}
      </Row>
      {isModalOpenReport && (
        <ModalReport
          open={isModalOpenReport}
          onOk={() => setIsModalOpenReport(false)}
          onCancel={() => setIsModalOpenReport(false)}
          getData={getData}
        />
      )}
      {isModalOpenDetail && (
        <DetailsReport
          open={isModalOpenDetail}
          onOk={() => setIsModalOpenDetail(false)}
          onCancel={() => setIsModalOpenDetail(false)}
          getData={getData}
        />
      )}
      {isModalOpen && (
        <ListModal
          open={isModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          getData={getData}
          handleChangeSubmit={handleChangeSubmit}
        />
      )}
      {modalAddFile && (
        <UploadFile
          open={modalAddFile}
          onOk={() => setModalAddFile(false)}
          onCancel={() => setModalAddFile(false)}
          getDataList={getData}
        />
      )}
    </div>
  )
}

export default ListReport
