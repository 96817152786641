import { Col, Row, Select, Space, Tooltip } from "antd"
import Search from "antd/es/input/Search"
import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import { AdminStyled } from "src/components/Layouts/component/LayoutAdmin/styled"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import TableCustom from "src/components/Table/CustomTable"
import {
  MainTableData,
  MainTableHeader,
  SubTableData,
  SubTableHeader,
} from "src/components/Table/CustomTable/styled"
import STORAGE, { getStorage } from "src/lib/storage"
import { renderButtonCircle } from "src/lib/utils"
import { StyleSearch } from "src/pages/ADMIN/BookUse/styled"
import PractiseService from "src/services/PractiseService"

const ApproveRegistration = ({ detail }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [dataSelect, setDataSelect] = useState([])
  const { id } = useParams()
  const userInfo = getStorage(STORAGE.USER_INFO)
  const [buttonBTC, setButtonBTC] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page_size: 10,
    page_number: 1,
  })
  const [button, setButtonData] = useState()
  const [dataSource, setDataSource] = useState([])
  const [total, setTotal] = useState(0)
  const [listRole, setListRole] = useState()
  const location = useLocation()
  useEffect(() => {
    if (!!userInfo) {
      userInfo?.role?.practise?.forEach(user => {
        if (user?.practise_id === id) {
          switch (user?.role_group_id) {
            case 7:
              setButtonBTC(true)
              break
            // Add other cases if needed
            default:
              setButtonBTC(false)
              break
          }
        }
      })
    }
  }, [userInfo, id])
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 60,
      render: (val, record, idx) => (
        <div className="text-center">
          {idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Họ và tên",
      dataIndex: "fullname",
      key: "fullname",
      width: 200,
      render: (val, record) => <div>{record?.User?.fullname}</div>,
    },
    {
      title: "Vai trò đăng ký",
      dataIndex: "team_name",
      key: "team_name",
      render: (val, record) => <div>{record?.team_name}</div>,
    },
    {
      title: (
        <>
          <MainTableHeader>Điện thoại</MainTableHeader>
          <SubTableHeader>Email</SubTableHeader>
        </>
      ),
      dataIndex: "phone_number",
      key: "phone_number",
      render: (val, record) => (
        <Tooltip title={val}>
          <MainTableData>{record?.User?.phone_number}</MainTableData>
          <SubTableData>{record?.User?.email}</SubTableData>
        </Tooltip>
      ),
    },
    {
      title: "Cơ quan",
      dataIndex: "organization_name",
      key: "organization_name",
      render: (val, record) => (
        <>
          <Tooltip title={record?.User?.Organization?.organization_name}>
            <MainTableData>
              {record?.User?.Organization?.organization_name}
            </MainTableData>
          </Tooltip>
          {buttonBTC && (
            <FloatActionWrapper size="small" className="float-action__wrapper">
              <Space>
                <ButtonCircle
                  title="Tiếp nhận"
                  iconName="check-blue-circle"
                  onClick={() =>
                    handleChangeSubmit({
                      record,
                      status: 1,
                      button_type: true,
                    })
                  }
                />
                <ButtonCircle
                  title="Từ chối"
                  iconName="cancel"
                  onClick={() =>
                    handleChangeSubmit({
                      record,
                      status: 0,
                      button_type: true,
                    })
                  }
                />
              </Space>
            </FloatActionWrapper>
          )}
        </>
      ),
    },
  ]
  const getListRole = async () => {
    try {
      setLoading(true)
      const res = await PractiseService.getPositionInPractise()
      if (res.isOk) {
        let ls = res.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.value,
          }
        })
        setListRole(ls)
        getData()
      }
    } finally {
      setLoading(false)
    }
  }
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setDataSelect(selectedRows)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: record =>
    //     disabled: record?.contribute_status !== 0,
    //     key: record.id

    // }),
    getCheckboxProps: record => {
      return {
        // disabled: record.status !== 0,
        name: record.user_id,
      }
    },
  }
  const getData = async () => {
    try {
      setLoading(true)
      const resp = await PractiseService.getRegisterInPractise({
        ...pagination,
        practise_id: id,
      })
      if (resp.isOk) {
        setDataSource(resp?.data)
        setTotal(resp?.total)
        setButtonData(resp?.button)
      }
    } finally {
      setLoading(false)
    }
  }
  const handleChangeSubmit = async event => {
    try {
      setLoading(true)
      const resp = await PractiseService.approvalOfRregistration({
        practise_id: id,
        status: event.status,
        ojb: !event.button_type
          ? dataSelect.map(item => ({
              user_id: item?.User?.id,
              team_type: item?.team_type,
              teamName: item?.team_name,
            }))
          : [
              {
                user_id: event?.record?.User?.id,
                team_type: event?.record?.team_type,
                teamName: event?.record?.team_name,
              },
            ],
      })
      if (resp.isOk) {
        getData()
        Notice({
          isSuccess: true,
          msg: resp?.message,
        })
        // setDataSource(resp?.data.result)
        // setTotal(resp?.data?.total)
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getListRole()
  }, [pagination])
  return (
    <AdminStyled>
      <Row gutter={[10, 15]}>
        <Col span={24}>
          <Row gutter={[10]}>
            <Col span={18}>
              <StyleSearch>
                <Search
                  placeholder="Nhập tên cần tìm kiếm"
                  allowClear
                  onSearch={event => {
                    setPagination({
                      ...pagination,
                      input: event,
                    })
                  }}
                  // onBlur={handleBlur}
                  // style={{
                  //     width: 200,
                  // }}
                />
              </StyleSearch>
            </Col>
            <Col span={6}>
              <Select
                style={{
                  width: "100%",
                }}
                allowClear
                showSearch
                placeholder="Vai trò"
                optionFilterProp="children"
                onChange={value => {
                  setPagination({
                    ...pagination,
                    team_type: value,
                  })
                }}
                // onSearch={onSearchSelect}
                // filterOption={filterOption}
                options={listRole}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row className="justify-content-space-between">
            <Col className="d-flex" style={{ alignItems: "center" }}>
              <div className="Text-title">
                Danh sách đăng ký tham gia diễn tập ({total || 0})
              </div>
            </Col>
            <Col>
              <Space>
                {button?.btn_Approval && (
                  <Button
                    btnType="primary"
                    onClick={() =>
                      handleChangeSubmit({
                        status: 1,
                        button_type: false,
                      })
                    }
                    disabled={selectedRowKeys?.length > 0 ? false : true}
                  >
                    Duyệt
                  </Button>
                )}
                {button?.btn_Refuse && (
                  <Button
                    btnType="primary"
                    onClick={() =>
                      handleChangeSubmit({
                        status: 0,
                        button_type: false,
                      })
                    }
                    disabled={selectedRowKeys?.length > 0 ? false : true}
                  >
                    Từ chối
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <TableCustom
            loading={loading}
            rowSelection={!detail ? rowSelection : null}
            isPrimary
            columns={columns}
            dataSource={dataSource}
            sticky={{ offsetHeader: -14 }}
            scroll={{ x: "800px" }}
            style={{ marginBottom: 4 }}
            pagination={{
              hideOnSinglePage: total <= 10,
              current: pagination.CurrentPage,
              PageSize: pagination.PageSize,
              responsive: true,
              total,
              locale: { items_per_page: "" },
              showSizeChanger: total > 10,
              onChange: (page, PageSize) => {
                setPagination({
                  ...pagination,
                  CurrentPage: page,
                  PageSize,
                })
                // getListRole({ ...pagination, CurrentPage: page, PageSize })
              },
            }}
            showPagination
            rowKey={record => `${record?.User?.id}`}
            footerLeft={<div className="d-flex mt-20" />}
            widthScroll={1200}
            textEmpty="Không có dữ liệu!"
          />
        </Col>
      </Row>
    </AdminStyled>
  )
}

export default ApproveRegistration
